<template>
  <div class="l-wrapper">
    <AppHeader />
    <main class="l-main -login">
      <div class="l-lead">
        <h1 class="c-lead">パスワードをお忘れの方</h1>
      </div>
      <div class="l-contents-white l-box">
        <ErrorMessage :errMsgs="errMsgs" />
        <p class="l-block">
          登録されているメールアドレスを入力してください。<br />
          パスワード再発行用のURLをお送りします。
        </p>
        <form>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>メールアドレス</label>
            </div>
            <div class="c-inputWrap__items">
              <div>
                <InputText
                  :value.sync="form.mail"
                  tooltip="メールアドレスの形式で（仮）"
                  inputType="email"
                  placeholder="メールアドレス"
                  :validation="validateMail"
                  :isValid.sync="isValidOf.mail"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- /.l-contents-white -->
      <div class="l-btns">
        <router-link to="/login" class="c-btn secondary small"
          >戻る</router-link
        >
        <div class="c-btn primary mid" @click="submit">送信</div>
      </div>
    </main>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import validation from "@/mixin/validation";
import { API_URL } from "@/const";
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  name: "password-reminder",
  components: {
    InputText,
  },
  mixins: [validation],
  data() {
    return {
      form: {
        mail: "",
      },
      errMsgs: [],
      isValidOf: {
        mail: false,
      },
    };
  },
  methods: {
    async submit() {
      await axios
        .post(API_URL.REMINDER.SENDMAIL, this.form)
        .then(() => {
          this.$router.push('/reminder/sendcomp');
        })
        .catch((err) => {
          this.errMsgs = err.response.data.message;
        });
      this.errMsgs = [];
      if (!this.isValidOf.mail) {
        this.errMsgs.push("メールアドレスは必須入力です。");
      }
    },
  },
  mounted() {
    // public系の画面はログアウトさせる
    this.$store.commit("auth/set", false);
    this.$store.commit("userInfo/set", {});
  },
};
</script>
